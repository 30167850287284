import { useModalWindow } from "react-modal-global";
import styles from "./PopupLeftSwipeLayout.module.css";

import useScrollBlock from "../../../customHooks/useScrollBlock";
// Helmet
import { setDefaultThemeColor } from '../../../features/helmet/helmetSlice';
import { useDispatch } from "react-redux";
// Motion
import { useAnimate, motion, useDragControls, useMotionValue } from "framer-motion";
import useMeasure from 'react-use-measure';

function PopupLeftSwipeLayout(props) {
  const modal = useModalWindow();
  const [blockScroll, allowScroll] = useScrollBlock();
  const dispatch = useDispatch();

  const defaultThemeColor = () => dispatch(setDefaultThemeColor());

  /**
   * Handles modal closure with animations
   */
  const closeModal = async () => {
    allowScroll();
    defaultThemeColor();

    // Animate the backdrop opacity to fade out
    animate(scope.current, 
      { opacity: [1, 0] }, 
      // { duration: 0.3, ease: "linear" }
    );

    // Animate the drawer to slide out to the right
    const xStart = typeof x.get() === 'number' ? x.get() : 0;
    await animate("#drawer", 
      { x: [xStart, "-100%"] }, // Slide out to the left
      // { duration: 0.3, ease: "easeInOut" }
    );

    modal.close();
  };

  const [scope, animate] = useAnimate(); // Scope for animations
  const controls = useDragControls(); // Controls for drag behavior
  const x = useMotionValue(0); // Motion value for drag behavior

  const [drawerRef, { height }] = useMeasure(); // Measure the drawer's dimensions

  return (
    <>
      {!modal.closed && (
        <motion.div
          ref={scope}
          className={styles.wrapper}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          // transition={{ duration: 0.3 }}
          onPointerDown={(e) => controls.start(e)} // Drag initialization
        >
          {/* Backdrop with close functionality */}
          <div className={styles.backdrop} onClick={closeModal}></div>

          {/* Drawer with animation */}
          <motion.div
            ref={drawerRef}
            id="drawer"
            initial={{ x: "-100%" }} // Start off-screen to the left
            animate={{ x: "0%" }} // Slide into view
            exit={{ x: "-100%" }} // Slide out to the left
            transition={{
              // duration: 0.3,
              ease: "easeInOut",
            }}
            style={{ x }} // Bind drag motion to x
            drag="x"
            onDragEnd={() => {
              // Close modal if dragged sufficiently to the left
              if (x.get() < -30) {
                closeModal();
              }
            }}
            dragControls={controls}
            dragListener={false}
            dragConstraints={{
              left: 0,
              right: 0,
            }}
            dragElastic={{
              left: 0.5,
              right: 0,
            }}
            className={`${styles.main_container} ${props.classNameLayout}`}
          >
            <div className={styles.popup_layout__container}>
              <button
                className={styles.closeDragBtn_wrapper}
                onClick={closeModal} // Close modal on button click
              >
                <div className={styles.closeDragBtn}></div>
              </button>
              <div className={styles.popup_layout__inner}>{props.children}</div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
}

export default PopupLeftSwipeLayout;
