import styles from './SNav.module.css'

import { selectUserFullName, selectUserTribe } from '../../../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'

// images
import img_library from './../../../assets/img/nav/library.svg'

import img_search from './../../../assets/img/nav/search.svg'
import img_notifications from './../../../assets/img/nav/notifications.svg'

import img_tribe_water from './../../../assets/img/tribe/waterCoin.svg'

import img_games from './../../../assets/img/nav/games.svg'
import img_school from './../../../assets/img/nav/school.svg'

import img_burger from './../../../assets/img/nav/burger.svg'

// 
// MODALS
import Modal_1 from './../../Modals/Modal_1';
import Modal_offCanvas_Left from '../../Modals/Modal_offCanvas/Modal_offCanvas_Left/Modal_offCanvas_Left';
import Modal_offCanvas_Right from '../../Modals/Modal_offCanvas/Modal_offCanvas_Right/Modal_offCanvas_Right';
import Modal_offCanvas_Search from '../../Modals/Modal_offCanvas/Modal_offCanvas_Search/Modal_offCanvas_Search';
import Modal_offCanvas_Notifications from '../../Modals/Modal_offCanvas/Modal_offCanvas_Notifications/Modal_offCanvas_Notifications';
import Modal_offCanvas_Tribe from '../../Modals/Modal_offCanvas/Modal_offCanvas_Tribe/Modal_offCanvas_Tribe';
import Modal_offCanvas_Games from '../../Modals/Modal_offCanvas/Modal_offCanvas_Games/Modal_offCanvas_Games';
import Modal_offCanvas_Study from '../../Modals/Modal_offCanvas/Modal_offCanvas_Study/Modal_offCanvas_Study';
import useScrollBlock from '../../../customHooks/useScrollBlock';

// Helmet
import { setNewThemeColor } from '../../../features/helmet/helmetSlice'


const UserTribe = ({ user_tribe }) => {
    const getImageByTribe = (tribe) => {
        switch (tribe) {
            case 'air':
                return img_tribe_water;
            case 'earth':
                return img_tribe_water;
            case 'fire':
                return img_tribe_water;
            case 'water':
                return img_tribe_water;
            default:
                return '/images/default.png';
        }
    };

    return (
        <img
            className={styles.tribeIcon}
            src={getImageByTribe(user_tribe)}
            alt={user_tribe ? `Image for ${user_tribe}` : 'No tribe'}
        />
    );
};

const SNav = () => {
    const full_name = useSelector(selectUserFullName)
    const user_tribe = useSelector(selectUserTribe)
    
    const dispatch = useDispatch();
    const changeThemeColor = (color) => dispatch(setNewThemeColor(color))


    const [blockScroll, allowScroll] = useScrollBlock();
    
    // Modal_offCanvas_Left
    const open_Modal_offCanvas_Left = () => {
        // setTimeout(function(){}, 150);
        // changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(Modal_offCanvas_Left);
    }
    // Modal_offCanvas_Right
    const open_Modal_offCanvas_Right = () => {
        // setTimeout(function(){}, 150);
        // changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(Modal_offCanvas_Right);
    }

    // Modal_offCanvas_Search
    const open_Modal_offCanvas_Search = () => {
        // setTimeout(function(){}, 150);
        // changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(Modal_offCanvas_Search);
    }
    // Modal_offCanvas_Notifications
    const open_Modal_offCanvas_Notifications = () => {
        // setTimeout(function(){}, 150);
        // changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(Modal_offCanvas_Notifications);
    }
    // Modal_offCanvas_Tribe
    const open_Modal_offCanvas_Tribe = () => {
        // setTimeout(function(){}, 150);
        // changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(Modal_offCanvas_Tribe);
    }
    // Modal_offCanvas_Games
    const open_Modal_offCanvas_Games = () => {
        // setTimeout(function(){}, 150);
        // changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(Modal_offCanvas_Games);
    }
    // Modal_offCanvas_Games
    const open_Modal_offCanvas_Study = () => {
        // setTimeout(function(){}, 150);
        // changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(Modal_offCanvas_Study);
    }

    return (
            <nav className={styles.nav}>
                <div className={styles.left}>
                    
                    <button className={styles.navBtn}  onClick={open_Modal_offCanvas_Left}>
                        <div className={styles.navItem_wrapper}>
                            <img src={img_library} alt="Navigation item" />
                        </div>
                    </button>

                   
                </div>

                <div className={styles.middle}>
                    <div className={`${styles.navItem_wrapper} ${styles.middle_left}`}>
                            <button className={styles.navBtn} onClick={open_Modal_offCanvas_Search}>
                                <img src={img_search} alt='nav item' />
                            </button>
                            <button className={styles.navBtn}  onClick={open_Modal_offCanvas_Notifications}>
                                <img src={img_notifications} alt='nav item' />
                            </button>
                            
                    </div>

                    <div className={`${styles.navItem_wrapper} ${styles.middle_middle}`}>
                        <button className={styles.navBtn}  onClick={open_Modal_offCanvas_Tribe}>
                            <UserTribe user_tribe={user_tribe} />
                        </button>
                    </div>

                    <div className={`${styles.navItem_wrapper} ${styles.middle_right}`}>
                            <button className={styles.navBtn}  onClick={open_Modal_offCanvas_Games}>
                                <img src={img_games} alt='nav item' />
                            </button>
                            <button className={styles.navBtn}  onClick={open_Modal_offCanvas_Study}>
                                <img src={img_school} alt='nav item' />
                            </button>
                    </div>
                </div>

                <div className={styles.right}>
                    <div className={styles.navItem_wrapper}>
                        <button className={styles.navBtn}  onClick={open_Modal_offCanvas_Right}>
                            <img className={styles.burger} src={img_burger} alt='menu' />
                            <span className={styles.full_name}>
                                {full_name}
                            </span>
                        </button>
                    </div>
                </div>
            </nav>
    )
}

export default SNav