import { createSlice } from '@reduxjs/toolkit'
import { jwtDecode } from 'jwt-decode'

const authSlice = createSlice({
    name: "auth",
    initialState: { 
                    token: null,
                    language: "",
                    tribe: "",
                    first_name: "",
                    last_name: ""
                },
    reducers: {
        setCredentials: (state, action) => {
            
            const { accessToken } = action.payload
            state.token = accessToken
            
            const decoded = jwtDecode(accessToken);
            state.language = decoded.userInfo.language
            state.tribe = decoded.userInfo.tribe;
            state.first_name = decoded.userInfo.first_name;
            state.last_name = decoded.userInfo.last_name;
        },
        setLanguage: (state, action) => {
            state.language = action.payload; // Directly update the language
        },
        logOut: (state, action) => {
            state.token = null
        },
    }
})

export const { setCredentials, logOut, setLanguage } = authSlice.actions

export default authSlice.reducer

export const selectCurrentToken = (state) => state.auth.token
export const selectCurrentLanguage = (state) => state.auth.language
export const selectUserFullName = (state) => `${state.auth.first_name} ${state.auth.last_name.charAt(0)}.`
export const selectUserTribe = (state) => state.auth.tribe