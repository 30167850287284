import styles from './StudentDetails.module.css';

import { useAddLessonMutation, useAddWordMutation, useGetLessonsQuery, useUpdateWordMutation } from '../../../features/users/usersApiSlice';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSocket } from '../../../app/SocketContext';

const StudentDetails = () => {
    const { id } = useParams();  // Extract `id` from URL

    const [addLesson, {
        data,
        isLoadingAddLesson,
        isSuccessAddLesson,
        isErrorAddLesson,
        errorAddLesson
    }] = useAddLessonMutation();

    const {
        data: lessons,
        isLoadingGetLessons, 
        isSuccessGetLessons,
        isErrorGetLessons,
        errorGetLessons,
        refetch: refetchLessons  // Add refetch function for lessons
    } = useGetLessonsQuery(id); // Automatically calls the API
    
    const [addWord, {
        data: addedWord,
        isLoadingAddWord, 
        isSuccessAddWord,
        isErrorAddWord,
        errorAddWord
    }] = useAddWordMutation();
    
    const [updateWord, {
        data: updatedWord,
        isLoadingUpdateWord, 
        isSuccessUpdateWord,
        isErrorUpdateWord,
        errorUpdateWord
    }] = useUpdateWordMutation();

    const [lessonName, setLessonName] = useState('');
    const [vocabularyEnglish, setVocabularyEnglish] = useState('');
    const [vocabularyRussian, setVocabularyRussian] = useState('');
    
    // State for the selected lesson
    const [selectedLesson, setSelectedLesson] = useState(null);

    const handleLessonSubmit = (e) => {
        e.preventDefault();
        setLessonName(''); // Clear the input after sending
        addLesson({ name: lessonName, userId: id });
    };

    const socket = useSocket();
    const handleVocabularySubmit = async (e) => {
        e.preventDefault();
        // Add the word through the API
        const newWord = { english: vocabularyEnglish, russian: vocabularyRussian, lessonId: selectedLesson._id };
        await addWord(newWord);
        socket.emit('new_word');

        // Update the vocabulary list locally to reflect the newly added word at the start
        setSelectedLesson(prevState => {
            const updatedVocabulary = [newWord, ...prevState.vocabulary]; // Add the new word at the start
            updatedVocabulary.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by createdAt
            return { ...prevState, vocabulary: updatedVocabulary }; // Update the lesson with the new vocabulary list
        });

        // Clear vocabulary inputs
        setVocabularyEnglish('');
        setVocabularyRussian('');
    };

    // Function to handle lesson click and set selected lesson
    const handleLessonClick = (lesson) => {
        setSelectedLesson(lesson);  // Set selected lesson to display vocabulary
    };

    // Sort vocabulary by `createdAt` in descending order

    const [sortedLessons, setSortedLessons] = useState([]);
    const [sortedVocabulary, setSortedVocabulary] = useState([]);
    // Effect to update sortedLessons when lessons data changes
    useEffect(() => {
        if (lessons) {
            const tempSortedLessons = lessons ? [...lessons].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
            setSortedLessons(tempSortedLessons);

            if(selectedLesson){
                const updateSelectedLesson = lessons?.find((lesson) => lesson._id === selectedLesson._id);
                const tempSortedVocabulary = updateSelectedLesson?.vocabulary ? [...updateSelectedLesson.vocabulary].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
                setSortedVocabulary(tempSortedVocabulary);
            }

            
        }
    }, [lessons, selectedLesson, refetchLessons]);  // Dependency array ensures that sorting happens when lessons change


    return (
        <section className={styles.section}>
            <div className={`${styles.column} ${styles.column_lesson}`}>
                <form className={styles.form} onSubmit={handleLessonSubmit}>
                    <textarea
                        className={styles.input}
                        type="text"
                        value={lessonName}
                        onChange={(e) => setLessonName(e.target.value)}
                        placeholder="Lesson Title"
                    />
                    <button className={styles.form_btn} type="submit">Send</button>
                </form>

                <ul>
                    {sortedLessons.length>0 && sortedLessons?.map((lesson) => (
                        <li 
                            key={lesson._id} 
                            onClick={() => handleLessonClick(lesson)}  // Make each lesson clickable
                            style={{ cursor: 'pointer', margin: '5px 0', color: 'blue' }}
                        >
                            {lesson.name} {/* Display lesson name */}
                        </li>
                    ))}
                </ul>
            </div>

            <div className={`${styles.column} ${styles.column_vocabulary}`}>
                {/* Check if a lesson is selected */}
                {selectedLesson && (
                    <div>
                        <h3>Vocabulary for {selectedLesson.name}</h3>
                        {/* Form to add new vocabulary */}
                        <form onSubmit={handleVocabularySubmit}>
                            <input
                                type="text"
                                value={vocabularyEnglish}
                                onChange={(e) => setVocabularyEnglish(e.target.value)}
                                placeholder="Vocabulary (English)"
                            />
                            <input
                                type="text"
                                value={vocabularyRussian}
                                onChange={(e) => setVocabularyRussian(e.target.value)}
                                placeholder="Translation (Russian)"
                            />
                            <button type="submit">Add Vocabulary</button>
                        </form>

                        {/* List of vocabulary items for the selected lesson */}
                        <ul>
                            {sortedVocabulary.length>0 && sortedVocabulary?.map((vocabularyItem, index) => (
                                <li key={index}>
                                    <div>
                                        <span>{vocabularyItem.english}</span>
                                        {[0, 1, 2].map((level) => (
                                            <button
                                                key={`english-${level}`}
                                                style={{
                                                backgroundColor:
                                                    vocabularyItem.levelOfEnglish === level
                                                    ? level === 0
                                                        ? "red"
                                                        : level === 1
                                                        ? "yellow"
                                                        : "green"
                                                    : "initial",
                                                }}
                                                onClick={async () => {
                                                    await updateWord({ lessonId: selectedLesson._id, wordId: vocabularyItem._id, levelType: "levelOfEnglish", newLevel: level})
                                                    refetchLessons()
                                                    socket.emit('new_word');
                                                    // console.log(`Level ${level} clicked for ${vocabularyItem.english}`);
                                                    // Empty function body for now
                                                }}
                                            >
                                                {level}
                                            </button>
                                        ))}
                                            
                                        <button onClick={() => socket.emit('openExercise', { word: vocabularyItem.english })}>open</button>
                                        <button onClick={() => socket.emit('closeExercise')}>close</button>
                                        -
                                        <span>{vocabularyItem.russian}</span>
                                        {[0, 1, 2].map((level) => (
                                            <button
                                                key={`english-${level}`}
                                                style={{
                                                backgroundColor:
                                                    vocabularyItem.levelOfRussian === level
                                                    ? level === 0
                                                        ? "red"
                                                        : level === 1
                                                        ? "yellow"
                                                        : "green"
                                                    : "initial",
                                                }}
                                                onClick={async () => {
                                                    // 
                                                    await updateWord({ lessonId: selectedLesson._id, wordId: vocabularyItem._id, levelType: "levelOfRussian", newLevel: level})
                                                    refetchLessons()
                                                    socket.emit('new_word');

                                                    // console.log(`Level ${level} clicked for ${vocabularyItem.english}`);
                                                    // Empty function body for now
                                                }}
                                            >
                                                {level}
                                            </button>
                                        ))}
                                        <button onClick={() => socket.emit('openExercise', { word: vocabularyItem.russian })}>open</button>
                                        <button onClick={() => socket.emit('closeExercise')}>close</button>
                                    </div>
                              </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className={`${styles.column} ${styles.column_lesson}`}>
                {/* You can add more sections if needed */}
            </div>

            <div className={`${styles.column} ${styles.column_lesson}`}>
                {/* You can add more sections if needed */}
            </div>
        </section>
    );
}

export default StudentDetails;
