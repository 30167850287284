import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const usersAdapter = createEntityAdapter({});

const initialState = usersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
      getStudents: builder.query({
        query: () => '/users/students',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
        keepUnusedDataFor: 5,
        transformResponse: (responseData) => {
          const loadedUsers = responseData.map((user) => {
            user.id = user._id; // Ensure the ID is properly mapped
            return user;
          });
          return usersAdapter.setAll(initialState, loadedUsers);
        },
        providesTags: (result, error, arg) => {
          if (result?.ids) {
            return [
              { type: 'Students', id: 'LIST' },
              ...result.ids.map((id) => ({ type: 'Student', id })),
            ];
          } else {
            return [{ type: 'Students', id: 'LIST' }];
          }
        },
      }),
      getLessons: builder.query({
        query: (id) => `/lessons/byUserId/${id}`, // Use the `id` parameter to build the endpoint
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
        keepUnusedDataFor: 5,
        providesTags: (result, error, arg) => {
          if (result?.ids) {
            return [
              { type: 'Lessons', id: 'LIST' },
              ...result.ids.map((id) => ({ type: 'Lesson', id })),
            ];
          } else {
            return [{ type: 'Lessons', id: 'LIST' }];
          }
        },
      }),
      addLesson: builder.mutation({
            query: initialUserData => ({
                url: '/lessons/byUserId',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [{ type: 'Lessons', id: 'LIST' }],
                
      }),
      addWord: builder.mutation({
            query: initialUserData => ({
                url: '/lessons/addWord',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [{ type: 'Lessons', id: 'LIST' }],
                
      }),
      updateWord: builder.mutation({
        query: (initialUserData) => ({
          url: '/lessons/updateWord',
          method: 'PATCH',
          body: {
            ...initialUserData,
          }
        }),
        // If you're updating a specific word, you should invalidate that lesson specifically
        invalidatesTags: (result, error, arg) => {
          return [
            { type: 'Lessons', id: 'LIST' }, // Invalidate list
            { type: 'Lesson', id: arg.lessonId }, // Invalidate the specific lesson based on `lessonId` passed in the args
          ];
        },
            
  }),
  }),
});

export const {
  useGetStudentsQuery,
  useGetLessonsQuery,
  useAddLessonMutation,
  useAddWordMutation,
  useUpdateWordMutation
} = usersApiSlice;

// Return the query result object
export const selectUsersResult = usersApiSlice.endpoints.getStudents.select();

// Creates memoized selector
const selectUsersData = createSelector(
  selectUsersResult,
  (usersResult) => usersResult.data ?? {} // Return empty object if data is undefined
);

// GetSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectUsersAll,
  selectById: selectUserById,
  selectIds: selectUsersIds,
} = usersAdapter.getSelectors((state) => selectUsersData(state) ?? initialState);
