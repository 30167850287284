import { useEffect, useState } from 'react';
import styles from './SardarCode.module.css';
import { useSocket } from '../../../app/SocketContext';
import { useSpeech } from "react-text-to-speech";
// 
import useAuth from '../../../customHooks/useAuth';

// 

const SardarCode = () => {
    

    const socket = useSocket();
    const [code, setCode] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        if (!socket) return
        
        // Define the listener
        const handleCodeUpdate = (newCode) => {
            setCode(newCode);
        };

        // Attach listener
        socket.on('code_update', handleCodeUpdate);


        socket.on('send_voice', (speech) => {
            console.log('Speech received:', speech);
            setText(speech);
        });

        // Clean up listener
        return () => {
            socket.off('code_update', handleCodeUpdate);
            socket.off('send_voice', (speech) => {
                console.log('Speech received:', speech);
                setText(speech);
            });
        };
    }, [socket]); // Dependency on socket ensures this runs correctly

    const CodeDisplay = ({ code }) => {
        const codeDigits = (code ? code.padEnd(6, ' ').split('') : Array(6).fill(''));

        return (
            <div className={styles.row}>
                {codeDigits.map((digit, index) => (
                    <div key={index} className={styles.codeSquare}>
                        {digit.trim() || ''}
                    </div>
                ))}
            </div>
        );
    };

    // Initialize and use speech
    const { start } = useSpeech({
        lang: "ru-RU",
        voiceURI: "com.apple.voice.compact.ru-RU.Milena",
        text: text
    });

    // Trigger speech whenever 'text' changes
    useEffect(() => {
        if (text) {
            start();
        }
    }, [text]);

    return (
        <div>
            <CodeDisplay code={code} />
        </div>
    );
};

export default SardarCode;
