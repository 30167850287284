import styles from './English.module.css';
import useAuth from '../../../../customHooks/useAuth';
// API
import { useGetLessonsQuery, useGetStudentsQuery } from '../../../../features/users/usersApiSlice';
import { Link } from 'react-router-dom';
// Socket
import { useSocket } from './../../../../app/SocketContext';
import { useEffect, useState } from 'react';
// Images
import img_vocabulary from './../../../../assets/img/icons/vocabulary.png'

const English = () => {
    
    




    const { userId, isStudent, isTeacher } = useAuth();
  const {
    data: students,
    isLoading, 
    isSuccess,
    isError,
    error
  } = useGetStudentsQuery(); // Automatically calls the API

  const {
    data: lessons,
    isLoadingGetLessons, 
    isSuccessGetLessons,
    isErrorGetLessons,
    errorGetLessons,
    refetch: refetchLessons  // Add refetch function for lessons
  } = useGetLessonsQuery(userId); // Automatically calls the API

  const socket = useSocket();

    const [showExercise, setShowExercise] = useState(false)
    const [exerciseContent, setExerciseContent] = useState("")

    useEffect(() => {
        if (!socket) return
        
        // Attach listener
        socket.on('update_list', ()=>{
            refetchLessons()
        });

        socket.on('openExercise', ({word}) => {
            setShowExercise(true)
            setExerciseContent(word)
        })

        socket.on('closeExercise', () => {
            setShowExercise(false)
        })

        // Clean up listener
        return () => {
            socket.off('update_list', ()=>{
                refetchLessons()
            });
        };
    }, [socket]); // Dependency on socket ensures this runs correctly

  // If lessons data is not yet available or is loading
  if (isLoadingGetLessons) {
    return (
      <section className={styles.section}>
        Loading lessons...
      </section>
    );
  }

  // Sort lessons by `createdAt` in descending order
  const sortedLessons = lessons ? [...lessons].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];

  // Sort vocabulary by `createdAt` within each lesson in descending order
  const sortedVocabularyByLesson = sortedLessons.map(lesson => {
    // If vocabulary exists for the lesson, sort it by `createdAt`
    const sortedVocabulary = lesson.vocabulary && lesson.vocabulary.length > 0 ? 
      [...lesson.vocabulary].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
    
    return {
      ...lesson,
      vocabulary: sortedVocabulary
    };
  });

  if (isStudent) {
    const VocabularyLevelCircle = ({ level }) => {
        const colors = ["#f95858", "#f9ba00", "#679e08"];
        return (
            <span
                style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: colors[level] || "initial",
                    borderRadius: "50%",
                    display: "inline-block",
                }}
            ></span>
        );
    };
  
    return showExercise ? (
                          <section className={styles.exercise}>
                              <div className={styles.exercise_background}>
                                  
                              </div>
                              <div className={styles.exercise_content}>
                                  {exerciseContent} 
                              </div>
                          </section>
                           
                            ) 
                        : (
                            <section className={styles.section}>
                                {sortedVocabularyByLesson.map((lesson) => (
                                <div key={lesson._id} className={styles.lesson_block}>
                                    <h2 className={styles.lesson_name}>{lesson.name}</h2>
                                    <ul className={styles.block}>
                                        <h3 className={styles.block_title}>
                                          <img src={img_vocabulary} alt="vocabulary"/>
                                          Словарь
                                        </h3>
                                        {lesson.vocabulary.length > 0 
                                        ? <>
                                              <li className={styles.header}>
                                                  <div className={styles.header_text}>
                                                    Слово
                                                  </div>
                                          
                                                  <div className={styles.header_text}>
                                                    Перевод
                                                  </div>
                                              </li>
                                          
                                              {lesson.vocabulary.map((vocabularyItem, index) => (
                                                  <li key={index} className={styles.word_block}>
                                                      <div className={`${styles.word} ${styles.word_english}`}>
                                                          <div className={styles.word_level}>
                                                              <VocabularyLevelCircle level={vocabularyItem.levelOfEnglish} />
                                                          </div>
                                                          <span className={styles.word_text}>{vocabularyItem.english}</span>
                                                      </div>
                                          
                                                      <div className={`${styles.word} ${styles.word_russian}`}>
                                                          <div className={styles.word_level}>
                                                              <VocabularyLevelCircle level={vocabularyItem.levelOfRussian} />
                                                          </div>
                                                          <span className={styles.word_text}>{vocabularyItem.russian}</span>
                                                      </div>
                                                  </li>
                                              ))}
                                          </>
                                        : (
                                            <li>No vocabulary available for this lesson</li>
                                        )}
                                    </ul>
                                </div>
                                ))}
                            </section>
                            );
                        }
  
  if (isTeacher) {
    if (isLoading) {
      return (
        <section className={styles.section}>
          Loading students...
        </section>
      );
    }

    return (
      <section className={styles.section}>
        <h1>Students</h1>
        {isSuccess && students && students.ids && students.entities ? (
          <ul className={styles.list}>
            {students.ids.map(id => {
              const student = students.entities[id];
              return (
                <li key={student.id} className={styles.student_card}>
                  <Link to={`/students/${student.id}`}>
                    {student.first_name} {student.last_name}
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : (
          <p>No students found.</p>
        )}
      </section>
    );
  }

  return null; // Optional: for when neither isStudent nor isTeacher is true
};

export default English;
