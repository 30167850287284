import { ModalController } from 'react-modal-global';
// Records
// import AModalRecordDiagnosis from './Records/AModalRecordDiagnosis';
// import AModalRecordComplaints from './Records/AModalRecordComplaints';
// import AModalRecordDrugs from './Records/AModalRecordDrugs';
// import AModalRecordTreatment from './Records/AModalRecordTreatment';

const Modal_2 = new ModalController({
  defaultParams: {},
  components: {
                // AModalRecordDiagnosis,
                // AModalRecordComplaints,
                // AModalRecordDrugs,
                // AModalRecordTreatment
              },
});

export default Modal_2;
