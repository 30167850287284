import styles from './Modal_offCanvas_Search.module.css';
import { useNavigate } from 'react-router-dom';

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from "../../../../customHooks/useScrollBlock";
import PopupBottomSwipeLayout from "../../Layouts/PopupBottomSwipeLayout";

// Helmet
import { setDefaultThemeColor } from '../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';
// Redux
import { useSendLogoutMutation } from '../../../../features/auth/authApiSlice';

const Modal_offCanvas_Search = () => {
    // Hooks for modal and scroll handling
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();

    // Redux dispatch and theme handling
    const dispatch = useDispatch();
    const defaultThemeColor = () => dispatch(setDefaultThemeColor());

    // Navigation
    const navigate = useNavigate();

    // Logout mutation and states
    const [sendLogout] = useSendLogoutMutation();

    /**
     * Handles modal closure, resetting theme color and enabling scroll.
     */
    const closeModal = () => {
        // setTimeout(function(){allowScroll()}, 150);
        defaultThemeColor();
        // allowScroll();
        modal.close();
    };

    const renderLayout = () => (
        <PopupBottomSwipeLayout classNameLayout={styles.popup__layout_bottom}>
            <div className={styles.header_container}>
                <div className={styles.header_title}>
                    <p>Search</p>
                </div>
                <button
                    className={styles.popup_layout__close}
                    type="button"
                    onClick={closeModal}
                >
                    &#9587;
                </button>
            </div>
        </PopupBottomSwipeLayout>
    );

    return renderLayout();
};

export default Modal_offCanvas_Search;
