import styles from './SardarExercise.module.css'
// socket IO
import { useSocket } from '../../../app/SocketContext'
import { useState } from 'react';

const SardarExercise = () => {
    const socket = useSocket()

    const handleIncrease = () => {
        socket.emit('request_code_update', { actionType: 'increase' });
    };
    
    const handleDecrease = () => {
        socket.emit('request_code_update', { actionType: 'decrease' });
    };
    const handleVoice = (speech) => {
        socket.emit('send_voice', { speech: speech });
    };

    // 
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
      
        socket.emit('send_voice', { speech: message.trim() });
        setMessage(''); // Clear the input after sending

    };

    return (
        
        <div className={styles.column}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <textarea
                    className={styles.input}
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Enter your message"
                />
                <button className={styles.form_btn} type="submit">Send</button>
            </form>
            
            <button className={styles.btn} onClick={() => handleVoice("Молодец Сардар!")}>Молодец Сардар!</button>
            <button className={styles.btn} onClick={() => handleVoice("Отличная работа! Так держать!")}>Отличная работа! Так держать!</button>
            <button className={styles.btn} onClick={() => handleVoice("Следующее задание это алфавит. Назови буквы по порядку.")}>Следующее задание это алфавит. Назови буквы по порядку.</button>
            <button className={styles.btn} onClick={() => handleVoice("Ура-а-а-а-а!")}>Урааааааа!</button>
            <button className={styles.btn} onClick={() => handleVoice("Следующее задание это догонялки")}>Следующее задание это догонялки</button>
            <div>
                <button className={styles.btn} onClick={handleDecrease}>Decrease</button>
                <button className={styles.btn} onClick={handleIncrease}>Increase</button>
            </div>
            
        </div>
    )
}

export default SardarExercise