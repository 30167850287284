// import React, { createContext, useContext, useEffect } from 'react';
// import io from 'socket.io-client';

// const SocketContext = createContext();
// const socket = io(process.env.REACT_APP_BASE_URL); // Initialize socket once

// export const useSocket = () => useContext(SocketContext);

// export const SocketProvider = ({ children }) => {
//     useEffect(() => {
//         return () => socket.disconnect(); // Disconnect on cleanup
//     }, []);

//     return (
//         <SocketContext.Provider value={socket}>
//             {children}
//         </SocketContext.Provider>
//     );
// };


import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import useAuth from '../customHooks/useAuth';

const SocketContext = createContext();
export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const user = useAuth();
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (user?.userId) {
            // Initialize socket when user is available
            const newSocket = io(process.env.REACT_APP_BASE_URL, {
                query: { userId: user.userId },
            });
            setSocket(newSocket);

            // Cleanup socket on unmount or user change
            return () => {
                newSocket.disconnect();
                setSocket(null);
            };
        }
    }, []);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
