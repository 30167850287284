import { useModalWindow } from "react-modal-global";
import styles from "./PopupRightSwipeLayout.module.css";

import useScrollBlock from "../../../customHooks/useScrollBlock";
// Helmet
import { setDefaultThemeColor, setNewThemeColor } from '../../../features/helmet/helmetSlice';
import { useDispatch } from "react-redux";
// Motion
import { useAnimate, motion, useDragControls, useMotionValue } from "framer-motion";
import useMeasure from 'react-use-measure'

function PopupRightSwipeLayout(props) {
  const modal = useModalWindow();
  const [blockScroll, allowScroll] = useScrollBlock();
  const dispatch = useDispatch();

  const defaultThemeColor = () => dispatch(setDefaultThemeColor());

  const closeModal = async () => {
      allowScroll();
      defaultThemeColor();

      animate(
        scope.current, 
        { opacity: [1,0] },
        // { duration: 0.3, ease: "linear" }
      )
      const yStart = typeof(x.get()) === 'number' ? x.get() : 0;
      await animate("#drawer", 
        { x: [yStart, height], },
        // { duration: 0.3, ease: "linear" }
      )

      modal.close()

    };

  const [scope, animate] = useAnimate()
  const controls = useDragControls()
  const x = useMotionValue(0)

  const [drawerRef, { height }] = useMeasure()
  
  return (
    <>
        {!modal.closed && (
            <motion.div
                ref={scope}
                className={styles.wrapper}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                onPointerDown={ (e) => controls.start(e)}
            >
              <div className={styles.backdrop} onClick={closeModal}></div>

            
              <motion.div
                      ref={drawerRef}
                      id="drawer"
                      initial={{ x: "100%" }}
                      animate={{ x: "0%" }}
                      transition={{
                        ease: "easeInOut",
                      }}
                      style={{ x }}
                      drag="x"
                      onDragEnd={() => {
                        console.log(x.get())
                        if(x.get()>30) {
                          closeModal()
                        }
                      }}
                      dragControls={controls}
                      dragListener={false}
                      dragConstraints={{
                        left: 0,
                        right: 0
                      }}
                      dragElastic={{
                        left: 0,
                        right: 0.5
                      }}
                      className={`${styles.main_container} ${props.classNameLayout}`}
              >
                <div className={styles.popup_layout__container}>
                  
                    <button
                      className={styles.closeDragBtn_wrapper}
                    >
                          <div className={styles.closeDragBtn}></div>
                    </button>
                  
                  <div className={styles.popup_layout__inner}>{props.children}</div>
                </div>
              </motion.div>
            </motion.div>
        )}
    </>
  );
}

export default PopupRightSwipeLayout;

