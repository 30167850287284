import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import { jwtDecode } from 'jwt-decode'
import { ROLES } from '../config/roles'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let roleFlags = {
        isStudent: false,
        isTeacher: false,
        isAdmin: false,
        isSuperAdmin: false,
        isOwner: false
    };
    
    let status = "Пользователь"
    if (token) {
        const decoded = jwtDecode(token)
        const { userId, email, role } = decoded.userInfo
        // console.log(decoded.userInfo)
        Object.keys(ROLES).forEach(key => {
            if (role === key) {
                let capitalisedKey = String(key).charAt(0).toUpperCase() + String(key).slice(1);
                roleFlags['is' + capitalisedKey] = true; // Assumes each key matches a flag in roleFlags
                status = ROLES[key]; // Set status using the display value from ROLES
            }
        });

        return { userId, email, role: status,  ...roleFlags }
    }

    return { userId: '', email: '', role: '', ...roleFlags }
}
export default useAuth