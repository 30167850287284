import styles from './VMain.module.css'
// Components
import VLogin from './VLogin'


const VMain = () => {
  return (
    <>
      <VLogin />
    </>
  )
}

export default VMain